@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
* {
    box-sizing: border-box;
}
html {
    height: 100%;
    width: 100%;
    margin: 0;
    position: relative;
    scroll-behavior: smooth;
}
body {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0;
    background-color: #101e25;
}

div.main-containt {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 640px;
    min-width: 264px;
    min-height: 480px;
    height: calc(100vh - 60px);
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
}
.main-body {
    max-width: 640px;
    min-height:370px;
    min-height: calc(100% - 110px);
    
    font-size: 20px;
    background-color: #fff;
    overflow-y: auto;
}

div.logo {
    width: 100%;
    height: 63px;
    font-family: 'Raleway', sans-serif;
    text-align: center;
    font-size: 40px;
    line-height: 63px;
    background-color: #5db2dd;
    padding-bottom: 5px;
}
div.logo button {
    float: right;
    height: 63px;
    padding: 15px;
    border: none;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    background-color: #1986bd;
    color: #fff;
}
div.logo button:hover {
    color: #fff;
    background-color: #146994;
}
span.sublogo {
    font-size: 45px;
    font-family: Yellowtail, cursive;
}


div.footer {
    width: 100%;
    background-color: #5db2dd;
    padding: 5px;
    min-height: 50px;
    text-align: center;
}
/* div.footer a {
    font-family: FontAwesome, "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    margin-left: 30px;
    font-size: 40px;
    text-shadow: 0 0 8px #000;
}
div.footer a:nth-child(1) {
    margin-left: 0;
    color: #1da1f2;
}
div.footer a:nth-child(2) {
    color: #000;
}
div.footer a:active,
div.footer a:hover {
    color: #000;
    text-shadow: none;
} */
.sidepanel {
    width: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background-color: #0f5070;
    min-height: 370px;
    height: calc(100% - 110px);
    overflow-x: hidden;
    transition: 0.5s;
}
.sidepanel a {
    text-decoration: none;
    font-size: 25px;
    color: #d3d0d0;
    display: block;
    transition: 0.3s;
    transform: rotateY(40deg);
    font-family: FontAwesome, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.sidepanel a:active,
.sidepanel a:hover {
    color: #fff;
    font-weight: 700;
    transform: rotateY(0);
}

.sidepanel a.active{
    font-weight: 700;
    transform: rotateY(0);
}


.sidepanel a:nth-child(1).active {
    color: #e0944d;
}
.sidepanel a:nth-child(2).active {
    color: #31d431;
}
.sidepanel a:nth-child(3).active {
    color: #cfcf18;
}
.sidepanel a:nth-child(4).active{
    color: #f5f5f5;
}

.open{
    width: 250px;
}
/* @media screen and (max-width: 1080px) {
    div.main-containt {
        width: 70%;
    }
}
@media screen and (max-width: 720px) {
    div.main-containt {
        width: 80%;
    }
    span.sublogo {
        font-size: 0;
    }
}
@media screen and (max-width: 360px) {
    div.main-containt {
        width: 90%;
    }
}
@media screen and (max-width: 264px) {
    div.main-containt {
        width: 100%;
    }
}
@media screen and (max-height: 600px) {
    div.main-containt {
        height: 100%;
        margin-top: 0;
    }
    div.margin {
        height: 0;
    }
} */

.main-body>p{
    font-size: 20px;
}
div.heading-body {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    margin-top: 5px;
}
