.main-body{
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    font-family: sans-serif;
    font-size: 16px;
}


div.calc{
    background-color: transparent;
    width: 500px;
    padding: 20px;
}

@media screen and (max-width: 500px) {
    div.calc{
        width: calc(100% - 20px);
    }

}


input,select,textarea{
    width: 100%;
    font-size: 20px;
    padding: 5px;
}
input{text-transform:uppercase}
textarea{resize: vertical;}

.calc-group{
    width: 100%;
    margin-top: 16px;
}


input,
select,
textarea,
button{
   border: 1px solid #333;
   color: #333;
}
label{
    color: #333;
    font-family: sans-serif;
}

textarea{
    font-family: sans-serif;
}


input:focus,
select:focus,
textarea:focus{
    outline: 2px solid #0f5070;
}


.calc-group>button{
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
}

.calc-group>button:active{
    outline: 2px solid gray;
}

.calc-group:nth-child(4){
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.calc-group:nth-child(4)>button{
    width: 30%;
}

.calc-group:nth-child(4)>button:nth-child(1){
    background-color: rgb(169, 226, 169);
}


.calc-group:nth-child(5)>button{
    width: 100%;
}

.wrongInput{
    outline: 2px solid red;
    background-color: rgb(235, 170, 170);
}
